
import { IInvoice } from '@/entity/invoice/invoice';
import { invoiceApi } from '@/wapi/invoice-api';
import InputElement from '@c/shared/input-element.vue';
import CurrencyInput from '@c/shared/currency-input.vue';
import PercentageInput from '@c/shared/percentage-input.vue';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IInvoiceLine } from '@/entity/invoice/invoice-line';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { vxm } from '@/store';
import { NU } from '@t/type';
import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import { ISignature } from '@/entity/pdf/pdf-signature';
import { IModelInvoice } from '@/entity/pdf/pdf-model-invoice';

@Component({
    components: {
        InputElement,
        CurrencyInput,
        PercentageInput
    }
})
export default class InvoiceInformation extends Vue {
    @Prop({ required: true }) id!: number;
    @Prop({ required: true }) projectId!: string;
    @Prop({ required: true, default: false }) isReadOnly!: boolean;

    savePending: boolean = false;

    public currentInvoice: NU<IInvoice> = {} as IInvoice;
    private allInvoiceModels: NU<IModelInvoice[]> = [];
    private allInvoiceSignings: NU<ISignature[]> = [];
    private optionInput: string = '';
    private option: boolean = false;

    get stateContext(): { validated: boolean | null | undefined, valid: boolean | null | undefined } {
        const isRefEmpty = this.currentInvoice && (this.currentInvoice.reference === '' || this.currentInvoice.reference === null);
        return { validated: true, valid: !isRefEmpty };
    }

    get isValidForm(): boolean {
        let res = true;
        this.currentInvoice?.invoiceLines?.forEach((element) => {
            res = res && element.cumulatedPercentage <= 100;
            res = res && element.invoicedPercentage <= 100;
        });
        return res;
    }

    get invoiceModels(): NU<IModelInvoice[]> {
        return this.allInvoiceModels;
    }

    get invoiceSignings(): NU<ISignature[]> {
        return this.allInvoiceSignings;
    }

    @Watch('currentInvoice.modelInvoiceId')
    private selectedModelChange() {
        const invModel = this.invoiceModels!.find((x) => x.id === this.currentInvoice!.modelInvoiceId);
        this.option = invModel?.hasOption ?? false;
        if (!this.option) {
            this.optionInput = '';
        } else {
            this.optionInput = invModel?.optionLabel ?? '';
        }
    }

    calculateDaysDifference(oldDate: Date | string, newDate: Date | string): number {
        const oldDateObj = new Date(oldDate);
        const newDateObj = new Date(newDate);

        // Calculate the difference in milliseconds
        const differenceInMillis = newDateObj.getTime() - oldDateObj.getTime();

        // Convert milliseconds to days
        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const differenceInDays = differenceInMillis / millisecondsPerDay;
        return differenceInDays;
    }

    addDaysToDate(date: Date | string, days: number): Date {
        const dateObj = new Date(date);
        dateObj.setDate(dateObj.getDate() + days);
        return dateObj;
    }

    @Watch('currentInvoice.date')
    private currentInvoiceDateChange(newDate, oldDate) {
        if (this.currentInvoice && this.currentInvoice.deadline && oldDate) {
            const oldDateStr = new Date(oldDate).toDateString();
            const newDateStr = new Date(newDate).toDateString();    
            const daysDifference = this.calculateDaysDifference(oldDateStr, newDateStr);
            this.currentInvoice.deadline = this.addDaysToDate(new Date(this.currentInvoice.deadline).toDateString(), daysDifference);
        }
    }

    isSiret = (evt: KeyboardEvent): void => {
        const keyPressed: string = evt.key;
    
        if (/[0-9]/.test(keyPressed) === false) {
            evt.preventDefault();
        }
    }

    isTVA = (evt: KeyboardEvent): void => {
        const keyPressed: string = evt.key;
        if (/[a-zA-Z0-9]/.test(keyPressed) === false) {
            evt.preventDefault();
        }
    }

    get agency(): string {
        if (this.isContractInvoice) {
            const contracts = vxm.project.contractsList ?? [];
            const selectedContract = contracts.filter((x) => x.id === this.currentInvoice?.contractId)[0];
            return selectedContract?.agency?.label ?? '';
        } else if (this.isPurchaseOrderInvoice) {
            const purchaseOrders = vxm.project.purchaseOrderList ?? [];
            const selectedpurchaseOrder = purchaseOrders.filter(
                (x) => x.id === this.currentInvoice?.purchaseOrderId
            )[0];
            return selectedpurchaseOrder?.agency?.label ?? '';
        }
        return '';
    }

    get client(): string {
        if (this.isContractInvoice) {
            const contracts = vxm.project.contractsList ?? [];
            const selectedContract = contracts.filter((x) => x.id === this.currentInvoice?.contractId)[0];
            return selectedContract?.client?.label ?? '';
        }
        return '';
    }

    get bank(): string {
        if (this.isContractInvoice) {
            const contracts = vxm.project.contractsList ?? [];
            const selectedContract = contracts.filter((x) => x.id === this.currentInvoice?.contractId)[0];
            return selectedContract?.bank?.label ?? '';
        }
        return '';
    }

    get supplier(): string {
        if (this.isPurchaseOrderInvoice) {
            const purchaseOrders = vxm.project.purchaseOrderList ?? [];
            const selectedpurchaseOrder = purchaseOrders.filter(
                (x) => x.id === this.currentInvoice?.purchaseOrderId
            )[0];
            return selectedpurchaseOrder?.supplier?.label ?? '';
        }
        return '';
    }

    get matter(): string {
        return `${vxm.project.projectData?.trigram}_${vxm.project.projectData?.designation}`;
    }

    get totalBillingET(): number {
        const res: number = Number(
            (
                this.currentInvoice?.invoiceLines
                    ?.map((x: IInvoiceLine) => x.invoicedTotalET)
                    .reduce((x: number, y: number) => x + y, 0) ?? 0
            ).toFixed(2)
        );
        if (this.currentInvoice !== null && this.currentInvoice !== undefined) this.currentInvoice.totalExcTax = res;
        return res;
    }

    get totalBillingETFormatter(): string {
        return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(this.totalBillingET);
    }

    get taxFormatter(): string {
        return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(Number(
            this.currentInvoice?.invoiceLines?.map(_ => Number(_.tax) * _.cumulatedAmount / 100)
                .reduce((a, b) => a + b))
        );
    }

    get taxPercentage () : number {
        return Number(
            this.currentInvoice?.invoiceLines?.map(_ => Number(_.tax) * _.cumulatedAmount / 100)
                .reduce((a, b) => a + b)) / this.totalBillingET * 100;
    }

    totalBilling(): number {
        var result = 0;
        if (this.currentInvoice !== null && this.currentInvoice !== undefined) {
            const tax = this.currentInvoice?.invoiceLines?.map(_ => Number(_.tax) / 100 * _.invoicedTotalET)
                .reduce((a, b) => a + b);
            result = tax! + this.totalBillingET;
        }
        return result;
    }

    get billingPercentage(): number {
        const totalAmountET =
            this.currentInvoice?.invoiceLines
                ?.map((x: IInvoiceLine) => x.totalAmountET)
                .reduce((x: number, y: number) => x + y, 0) ?? 0;
        return Number(
            (totalAmountET > 0 && this.totalBillingET > 0 ? (this.totalBillingET / totalAmountET) * 100 : 0).toFixed(2)
        );
    }

    get isContractInvoice(): boolean {
        return this.currentInvoice?.contractId !== null && this.currentInvoice?.contractId !== 0;
    }

    get isPurchaseOrderInvoice(): boolean {
        return this.currentInvoice?.purchaseOrderId !== null && this.currentInvoice?.purchaseOrderId !== 0;
    }

    private invoiceForContractFields: IBootstrapTableColumn[] = [
        { label: 'Désignation', key: 'designation', sortable: false, thStyle: 'min-width:230px' },
        { label: 'Quantité', key: 'quantity', sortable: false, thStyle: 'width:80px' },
        { label: 'PU HT (€)', key: 'unitPrice', sortable: false, formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'Total HT (€)', key: 'totalAmountET', sortable: false, formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'Fact (%)', key: 'invoicedPercentage', sortable: false, thStyle: 'width:90px' },
        { label: 'Facturé HT (€)', key: 'invoicedTotalET', sortable: false, formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'TVA (%)', key: 'tax', sortable: false, thStyle: 'width:90px' },
        { label: 'Cumul facturé (%)', key: 'cumulatedPercentage', sortable: false },
        { label: 'Cumul facturé HT (€)', key: 'cumulatedAmount', sortable: false, formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'Avoir', key: 'isCredit', sortable: false, tdClass: 'td-is-credit-width' },
        { key: 'delete', sortable: false, tdClass: 'td-delete-width' }
    ];

    private invoiceForPurchaseOrderFields: IBootstrapTableColumn[] = [
        { label: 'Phase ', key: 'phase', sortable: false, thStyle: 'min-width:230px' },
        { label: 'Désignation', key: 'designation', sortable: false, thStyle: 'min-width:230px' },
        { label: 'Quantité', key: 'quantity', sortable: false, thStyle: 'width:80px' },
        { label: 'PU HT (€)', key: 'unitPrice', sortable: false, formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'Total HT (€)', key: 'totalAmountET', sortable: false, formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'Fact (%)', key: 'invoicedPercentage', sortable: false, thStyle: 'width:90px' },
        { label: 'Facturé HT (€)', key: 'invoicedTotalET', sortable: false, formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'Avoir', key: 'isCredit', sortable: false },
        { key: 'delete', sortable: false }
    ];

    private async mounted(): Promise<void> {
        this.getInvoice(this.id);

        await vxm.user.checkIsAdmin();
        await this.getInvoiceModels();
        await this.getInvoiceSignings();
    }

    private async getInvoice(id: number): Promise<void> {
        if (id && id !== 0) {
            const datas = await invoiceApi.getBase(id + '');
            if (isCallValidAndNotCancelled<IInvoice | null>(datas)) {
                this.currentInvoice = datas?.datas;
                if (this.isContractInvoice) this.calculatecumuls();
            }
        }
    }

    private async getInvoiceModels(): Promise<void> {
        const datas = await invoiceApi.getAllModelInvoice();
        if (isCallValidAndNotCancelled<IModelInvoice[] | null>(datas)) {
            this.allInvoiceModels = datas?.datas;
        }
    }

    private async getInvoiceSignings(): Promise<void> {
        const datas = await invoiceApi.getAllSigningInvoice();
        if (isCallValidAndNotCancelled<ISignature[] | null>(datas)) {
            this.allInvoiceSignings = datas?.datas;
        }
    }

    @Watch('id')
    switchInvoiceById(newVal: number, oldVal: number): void {
        if (newVal) {
            this.getInvoice(newVal);
        }
    }

    @Watch('currentInvoice.isPayed')
    isPayedEvent(newVal: boolean, _oldVal: boolean): void {
        if (newVal && this.currentInvoice && !this.currentInvoice.paymentDate) {
            this.currentInvoice.paymentDate = new Date();
        } else if (!newVal && this.currentInvoice && this.currentInvoice.paymentDate !== null) {
            this.currentInvoice.paymentDate = undefined;
        }
    }

    get formatedCreationDate(): string {
        return (
            format(new Date(String(this.currentInvoice?.creationDate)), 'EEEE dd MMMM yyyy à HH:mm:ss', {
                locale: frenchLocale
            }) ?? ''
        );
    }

    get formatedModificationDate(): string {
        return (
            format(new Date(String(this.currentInvoice?.modificationDate)), 'EEEE dd MMMM yyyy à HH:mm:ss', {
                locale: frenchLocale
            }) ?? ''
        );
    }

    private calculatecumuls(): void {
        const invoiceLines: IInvoiceLine[] = [];
        vxm.project.invoicesList
            ?.filter((x) => x.contractId === this.currentInvoice?.contractId && x.id <= (this.currentInvoice?.id ?? 0))
            ?.forEach((y) => {
                y.invoiceLines?.forEach((element) => {
                    invoiceLines.push(element);
                });
            });
    }

    onRecalculateLineTotalET(item: IInvoiceLine): void {
        if (
            item &&
            item.totalAmountET !== null &&
            item.totalAmountET !== undefined &&
            item.invoicedPercentage !== null &&
            item.invoicedPercentage !== undefined
        ) {
            let invoicedPercentage = 0;
            if (item.isCredit && item.invoicedPercentage > 0) {
                invoicedPercentage = -1 * item.invoicedPercentage;
            } else if (!item.isCredit && item.invoicedPercentage < 0) {
                invoicedPercentage = -1 * item.invoicedPercentage;
            } else {
                invoicedPercentage = item.invoicedPercentage;
            }
            item.invoicedPercentage = Number(invoicedPercentage.toFixed(2));
            item.invoicedTotalET = Number((item.totalAmountET * (item.invoicedPercentage / 100)).toFixed(2));
            if (this.isContractInvoice) this.calculatecumuls();
        }
    }

    onRecalculateLineTotal(item: IInvoiceLine): void {
        if (
            item &&
            item.quantity !== null &&
            item.quantity !== undefined &&
            item.unitPrice !== null &&
            item.unitPrice !== undefined
        ) {
            item.totalAmountET = Number((item.quantity * item.unitPrice).toFixed(2));
            this.onRecalculateLineTotalET(item);
        }
    }

    onRecalculateLineinvoicedPercentage(item: IInvoiceLine): void {
        if (
            item &&
            item.totalAmountET !== null &&
            item.totalAmountET !== undefined &&
            item.invoicedTotalET !== null &&
            item.invoicedTotalET !== undefined
        ) {
            item.invoicedPercentage = Number(((item.invoicedTotalET / item.totalAmountET) * 100).toFixed(2));
        }
    }

    onRecalculateLineinvoicedTVAPercentage(item: IInvoiceLine): void {
        this.onRecalculateLineTotalET(item);
            this.currentInvoice!.total = Number(this.currentInvoice?.invoiceLines?.map(_ => _.totalAmountET + (_.totalAmountET * Number(_.tax) / 100)).reduce((a, b) => a + b));
    }

    public invoiceGenerated(): boolean {
        return this.currentInvoice?.isGenerated ?? false;
    }

    async saveInvoice(): Promise<void> {
        if (this.currentInvoice) {
            // Calculate TotalBilling (not binded)
            this.currentInvoice.total = this.totalBilling();
            this.currentInvoice.signingId = this.currentInvoice.signingId ?? 1;
            this.savePending = true;
            // this.updateLinePositionNumber();
            const callData = await invoiceApi.patchBase(this.currentInvoice.id + '', this.currentInvoice);
            if (isCallValidAndNotCancelled(callData)) {
                this.getInvoice(this.id);
                await vxm.project.updateInvoices(this.projectId);
                this.$bvToast.toast('Enregistrement effectué avec succès', {
                    title: `Facture: ${this.currentInvoice.reference}`,
                    variant: 'success',
                    solid: true
                });
            }
            this.savePending = false;
        }
    }

    get hasRevisingLine(): boolean {
        return this.currentInvoice?.invoiceLines?.find((x) => x.isRevisingLine === true) !== undefined;
    }

    addInvoiceLine(): void {
        const lineToAdd: IInvoiceLine = {} as IInvoiceLine;
        lineToAdd.invoiceId = this.currentInvoice?.id ?? 0;
        lineToAdd.designation = this.isContractInvoice ? 'Révision' : '';
        lineToAdd.quantity = 1;
        lineToAdd.unitPrice = 0;
        lineToAdd.totalAmountET = 0;
        lineToAdd.invoicedPercentage = 0;
        lineToAdd.invoicedTotalET = 0;
        lineToAdd.cumulatedAmount = 0;
        lineToAdd.cumulatedPercentage = 0;
        lineToAdd.tax = 20;
        lineToAdd.isRevisingLine = true;
        this.currentInvoice?.invoiceLines?.push(lineToAdd);
    }

    deleteInvoiceLine(item: IInvoiceLine): void {
        const index = this.currentInvoice?.invoiceLines?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.currentInvoice?.invoiceLines?.splice(index, 1);
        }
    }

    async redirectToPdf(): Promise<void> {
        await this.saveInvoice();
        this.$router.push({ name: 'invoice-pdf', params: { invoiceId: this.id + '' } });
    }

    redirectToDetails(): void {
        this.$router.push({ name: 'invoice-pdf', params: { invoiceId: this.id + '' } });
    }

    get isAdmin() {
        return vxm.user.isAdmin;
    }

    get currentInvoiceLines() {
        return this.currentInvoice?.invoiceLines ?? [];
    }
}
